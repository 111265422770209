import React, { Component } from "react";
import BodyClassName from "react-body-classname";
import { Modal, Button, Alert } from "react-bootstrap";
import $, { error } from "jquery";
import goback from "../../images/dashboard/go-back.svg";
import infoicon from "../../images/admin/info-icon.svg";
import Header from "../Common/Header";
import NewSidebar from "../new-sidebar";
import SuccessPopup from "../Setting/Sucesspopup";
import Loader from "../../Loader";
import "bootstrap/dist/css/bootstrap.min.css";
import editImg from "../../images/admin/Group 68026.svg";
import Group68025 from "../../images/admin/Group 68025.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InfoIcon from "../../images/dashboard/ic_error.svg";
import close from "../../images/dashboard/modal-close.svg";
import gallery from "../../images/gallery.svg";
import modalclose1 from "../../images/dashboard/modal-close.svg";
import edit from "../../images/edit.svg";
import Tooltip from "react-bootstrap/Tooltip";
import { isImageFile } from "../../helper/CommonUtils";
import { Link } from "react-router-dom";
import InvoicesComponent from "../Setting/InvoicesComponent";
import ConfirmationModal from "../Setting/ConfirmationModal";
import infoIcon from "../../images/dashboard/ic_error.svg";
import OverLimitNotice from "../Common/Limit/OverLimitNotice";

import {
  getAllsubscription,
  userInfo,
  getSubAdminSubscriptionLimits,
  updateMembership,
  deleteMembership,
  getSubsById,
  subscriptionType,
  subscriptionTypeList,
  saveMembership,
  getAllMemberThatBuyMembership,
  subadminAllDataCount,
} from "../../helper/service";

class MemberShips extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen4: this.props.membershipIsOpen,
      gallery: gallery,
      membesship_type: "Paid",
      membershipLevelIsFree: true,
      substype: "FREE",
      isOpen4: false,
      subscriptionTypeData: [],
      isOpen6: false,
      is_loader: false,
      selectedTab: "first",
      monthly_price: "",
      yearly_price: "",
      amountMonthly: "",
      amountYearly: "",
      email: "",
      content: "",
      successMessageSubject: "",
      successMessageDescription: "",
      renewalReminderSubject: "",
      renewalReminderDescription: "",
      thumbnail_image_error: "",
      membershipLevelCount: 0,
      subscriptionAllowed: 0,
      totalSubscriptions: 0,
      is_edit: false,
      isSuccess: false,
      ascendingSort: true,
      showMemberShipPage: true,
      showTransactionPage: false,
      pauseMembership: false,
      message: '',
      warningModalTitle: '',
      modalType: '',
      showWarningModal: false,
      hideMembership: false,
      hasFreeSubscription: true,
      partnerSubsData: {},
      userModuleUsedLength: {},
      subType: '',
    };
  }
  openModal4 = () => {
    this.setState({ isOpen4: true, isOpen5: false, isOpen7: false, isOpen6: false });
  }
  openModal7() {
    this.setState({ isOpen7: true });
  }

  closeModal7() {
    this.setState({ isOpen7: false });
  }

  openModal4() {
    this.setState({
      isOpen4: true,
      isOpen5: false,
      isOpen7: false,
    });
  }
  openModal5 = () =>
    this.setState({ isOpen5: true, isOpen4: false, isOpen6: false });
  closeModal5 = () => this.setState({ isOpen5: false });

  openModal6 = () =>
    this.setState({ isOpen6: true, isOpen5: false, isOpen7: false, isOpen4: false });
  closeModal6 = () => {
    this.setState({ isOpen6: false, isOpen4: false });
    this.setState({
      tier: "",
      description: "",
      subAdminId: "",
      img: "",
      subscriptionTypeId: "",
      thumbnailImg: "",
      signUpBtnText: "",
      substype: "",
      emailId: "",
      emailContent: "",
      amountOneTime: "",
      amountMonthly: "",
      amountYearly: "",
      successMessageSubject: "",
      successMessageDescription: "",
      renewalReminderSubject: "",
      renewalReminderDescription: "",
      buttontext: "",
      membesship_type: "Paid",
      email: "",
      content: "",
      onetime_price: "",
      monthly_price: "",
      yearly_price: "",
      pablyMonthlyPlanId: "",
      pablyYearlyPlanId: "",
      gallery: gallery,
      isload: false,
      is_edit: false,
      membershipLevelIsFree: false
    });
  };

  openModal7 = () => this.setState({ isOpen7: true });
  closeModal7 = () => this.setState({ isOpen7: false });
  closeModal4 = () => {
    this.resetAllErrors();
    this.setState({ isOpen4: false, is_edit: false });
    this.setState({
      tier: "",
      description: "",
      subType: "",
      buttontext: "",
      gallery: gallery,
      substype: "",
      content: "",
      email: "",
      successMessageSubject: "",
      successMessageDescription: "",
      renewalReminderSubject: "",
      renewalReminderDescription: "",
      idd: "",
      pablyMonthlyPlanId: "",
      pablyYearlyPlanId: "",
      membesship_type: "Paid",
      membershipLevelIsFree: false

    });
  };

  closeSuccessModel = () => {
    this.setState({ isSuccess: false });
  }

  selectPlan(planname) {
    $(document).ready(function () {
      $(".frequency-btn").removeClass("active");
      $("#" + planname).addClass("active");
    });
    this.setState({ currentPlan: planname });
  }
  subscriptionTypeIterate(data) {
    let datas = data.validSubs.map(function (subs) {
      return (
        <div className="col-4" onClick={this.selectPlan.bind(this, subs._id)}>
          <div className="frequency-btn" id={subs._id}>
            {subs.name}
          </div>
        </div>
      );
    }, this);
    this.setState({
      subscriptionTypeData: datas,
    });
  }
  componentDidMount() {
    const userId = localStorage.getItem("user_id");
    this.setState({
      is_loader: true,
    });
    userInfo(userId).then((data) => {
      var isPaid = data?.result?.validUser?.subscriptionId && data?.result?.validUser?.subscriptionId.length > 0 && data?.result?.validUser?.subscriptionId[0].subsType !== "Free";
      this.setState({ partnerSubsData: data.result.validUser.subscriptionId?.[0]?.subsId })
      this.setState({ hasFreeSubscription: !isPaid });
      this.setState({
        membershipLevelIsFree: !isPaid,
      });
    });
    subadminAllDataCount(userId)
      .then((userData) => {
        this.setState({ userModuleUsedLength: userData.result.partnerData })
      })
      .catch((error) => {
        console.error("Error fetching user used data:", error);
      });
    subscriptionTypeList()
      .then((data) => {
        this.subscriptionTypeIterate(data.result);
      });

    this.getSubscriptionMemberAccessLevel();
    getSubAdminSubscriptionLimits(userId).then((res) => {
      this.setState({
        is_loader: false,
        // membershipLevelCount: res.data.data.membership_level_count,
        // subscriptionAllowed: res.data.data.subscription_allowed,
        // totalSubscriptions: res.data.data.total_subscriptions,
      });
    });
    userInfo(localStorage.getItem("user_id")).then((res) => {
      this.setState({
        is_loader: false,
        email: res.result.validUser.email,
      });
    });
  }
  getSubscriptionMemberAccessLevel() {
    getAllsubscription().then((res) => {
      let newArray = [];
      res.result.map((item) => {
        getAllMemberThatBuyMembership(item._id).then((i) => {
          item.memberCount = i.result.validSubs.length;

          newArray.push(item);
          newArray = newArray.sort((a, b) => {
            const dateA = new Date(a.name);
            const dateB = new Date(b.name);

            // If dates are equal, compare times
            if (dateA.getTime() === dateB.getTime()) {
              return dateA - dateB;
            }
            return dateA - dateB;
          });
          this.setState({
            is_loader: false,
            subscriptionTypeData: newArray,
          });
        });
      });
    });
  }
  deleteMemberShipLevelSubscription(id) {
    this.setState({ is_loader: true });
    deleteMembership(id).then((data) => {
      this.listsubscriptionType();
      this.setState({ is_loader: false });
      this.getSubscriptionMemberAccessLevel();
      this.setState({
        is_loader: false,
        isSuccess: true,
        actionResultMessage: "Membership deleted Successfully.",
      });
    });
  }
  onChange = (event) => {
    // event.preventDefault();
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      membershipLevelIsFree: value?.toUpperCase() === "FREE",
    });
  };

  onImageChange = (event) => {
    this.setState({ thumbnail_image_error: "" });
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (isImageFile(file)) {
        let img2 = URL.createObjectURL(event.target.files[0]);
        let img = event.target.files[0];
        this.setState({
          img: img,
          gallery: img2,
        });
      } else {
        this.setState({
          thumbnail_image_error: "Please upload a valid image file.",
        });
      }
    }
  };

  submitForm = async (event) => {
    event.preventDefault();

    if (this.validate()) {
      // this.setState({
      //   membesship_type: this.state.hasFreeSubscription ? "Free" : "Paid",
      // });
      this.state.hasFreeSubscription ? this.openModal6() : this.state.membershipLevelIsFree ? this.openModal6() : this.openModal5();
    }
  };

  submitForm1 = async (event) => {
    event.preventDefault();
    let userId = localStorage.getItem("user_id");
    if (this.state.membesship_type === "Paid") {
      if (!this.state.amountMonthly && !this.state.amountYearly) {
        this.setState({
          message: "Please enter Monthly or Annual price.",
          showWarningModal: true,
          modalType: "error",
          warningModalTitle: "Action Required"
        });
        return false;
      }
    }
    this.openModal6();
  };

  submitForm2 = async (event) => {
    event.preventDefault();
    let userId = localStorage.getItem("user_id");

    if (!this.state.successMessageDescription) {
      this.setState({
        message: "Please enter success message description.",
        showWarningModal: true,
        modalType: "error",
        warningModalTitle: "Action Required"
      });
      return false;
    }
    // else if (!this.state.renewalReminderSubject) {
    //   alert("Please enter renewal reminder subject.");
    //   return false;
    // } else if (!this.state.renewalReminderDescription) {
    //   alert("Please enter renewal reminder description.");
    //   return false;
    // }

    const Datas = {
      name: new Date().toISOString(),
      tier: this.state.tier,
      description: this.state.description,
      subType: this.state.membesship_type,
      subAdminId: userId,
      subscriptionTypeId: this.state.currentPlan,
      thumbnailImg: this.state.img,
      signUpBtnText: this.state.buttontext,
      substype: this.state.membesship_type,
      emailId: this.state.email,
      emailContent: this.state.content,
      amountOneTime: this.state.monthly_price || 0,
      pauseMembership: this.state.pauseMembership || false,
      hideMembership: this.state.hideMembership || false,
      amountMonthly: this.state.amountMonthly || 0,
      amountYearly: this.state.amountYearly || 0,
      pablyMonthlyPlanId: this.state.pablyMonthlyPlanId,
      pablyYearlyPlanId: this.state.pablyYearlyPlanId,
      successMessageSubject: this.state.successMessageSubject,
      successMessageDescription: this.state.successMessageDescription,
      renewalReminderSubject: this.state.renewalReminderSubject,
      renewalReminderDescription: this.state.renewalReminderDescription,
      idd: this.state.idd,
    };
    console.log("Form Data:", Datas);

    this.setState({ is_loader: true, isSuccess: false });
    if (this.state.is_edit) {
      updateMembership(Datas)
        .then((res) => {
          this.getSubscriptionMemberAccessLevel();
          if (res?.response?.data?.body?.includes("E11000 duplicate key error collection: b.subscriptions index: tier_1 dup key:")) {
            this.setState({
              message: ("Enter different 'Tier' name! Given tier name already exists.\n Failed to update Membership."),
              showWarningModal: true,
              modalType: "error",
              warningModalTitle: "ERROR!!!",
            });
          }
          else if (res?.data?.message === 'Sucessfully Updated Subscription ') {
            this.setState({
              is_loader: false,
              isSuccess: true,
              actionResultMessage: "Membership Updated Successfully.",
            });
          }
        })
        .finally(() => {
          this.setState({ is_loader: false });
        });
      this.closeModal6();
    } else {
      saveMembership(Datas)
        .then(async (data) => {
          if (data?.response?.data?.body === "Subscription Creation amount exhausted!") {
            this.setState({
              message: ("You have reached the limit of maximum Memberships allowed, consider upgrading your subscription!"),
              showWarningModal: true,
              modalType: "error",
              warningModalTitle: "ERROR!!!",
            });
          }
          else if (data?.response?.data?.body === "Subscription not found!") {
            this.setState({
              message: ("Subscription not found! Please subscribe to a plan first."),
              showWarningModal: true,
              modalType: "error",
              warningModalTitle: "ERROR!!!",
            });
          }
          else if (data?.response?.data?.body?.includes("E11000 duplicate key error collection: b.subscriptions index: tier_1 dup key:")) {
            this.setState({
              message: ("Enter different 'Tier' name! Given tier name already exists.\n Failed to add Membership."),
              showWarningModal: true,
              modalType: "error",
              warningModalTitle: "ERROR!!!",
            });
          }
          else if (data?.data?.message === "Subscription Add Successfully") {
            this.setState({
              is_loader: false,
              isSuccess: true,
              actionResultMessage: "Membership Added Successfully.",
            });
          }

          this.getSubscriptionMemberAccessLevel();
          this.closeModal6();
          await userInfo(userId);


        })
        .catch((error) => {
          this.setState({
            message: (
              error?.response?.data?.body != null
                ? error?.response?.data?.body?.includes("tier")
                  ? `Enter different 'Tier' name! Given tier name already exists.`
                  : error?.response?.data?.body
                : `Some error occurred, try again!`
            ),
            showWarningModal: true,
            modalType: "error",
            warningModalTitle: "ERROR!!!",
            isSuccess: false,
          });
          this.setState({ is_loader: false });
          this.closeModal6();
        })
        .finally(() => {
          this.setState({ is_loader: false });
        });
    }
  };

  resetAllErrors() {
    this.setState({
      tier_error: '',
      description_error: '',
      thumbnail_image_error: ''
    })
  }
  validate = () => {
    let formIsValid = true;
    const emailRegex = /\S+@\S+\.\S+/;

    if (!this.state.tier) {
      formIsValid = false;
      this.setState({ tier_error: "*Tier Required" });
    } else {
      this.setState({ tier_error: "" });
    }

    if (!this.state.description) {
      formIsValid = false;
      this.setState({ description_error: "*Description Required" });
    } else {
      this.setState({ description_error: "" });
    }

    // if (!this.state.buttontext) {
    //   formIsValid = false;
    //   this.setState({ buttontext_error: "*Required" });
    // } else {
    //   this.setState({ buttontext_error: "" });
    // }

    return formIsValid;
  };
  reverseCheckData = (event) => {
    const sortedData = [...this.state.subscriptionTypeData];

    if (event.target.value === "high-price") {
      sortedData.sort((a, b) => b.amountMonthly - a.amountMonthly);
    }
    else if (event.target.value === "low-price") {
      sortedData.sort((a, b) => a.amountMonthly - b.amountMonthly);
    }
    else {
      sortedData.sort((a, b) => {
        const dateA = new Date(a.name);
        const dateB = new Date(b.name);

        if (dateA.getTime() === dateB.getTime()) {
          return dateA - dateB;
        }

        return dateA - dateB;
      });
    }

    this.setState((prevState) => ({
      subscriptionTypeData: sortedData,
      ascendingSort: !prevState.ascendingSort,
    }));
  };

  async editMembership(subsId) {
    this.setState({ is_loader: true });
    await getSubsById(subsId).then((data) => {
      this.setState({ is_loader: false, is_edit: true });
      this.setState({
        tier: data.result.validSubs.tier,
        description: data.result.validSubs.description,
        subType: data.result.validSubs.subType,
        buttontext: data.result.validSubs.signUpBtnText,
        gallery:
          data?.result?.validSubs?.thumbnailImg &&
            data?.result?.validSubs?.thumbnailImg !== "undefined"
            ? data?.result?.validSubs?.thumbnailImg
            : gallery,
        substype: data.result.validSubs.substype,
        membesship_type: data.result.validSubs.substype,
        content: data.result.validSubs.emailContent,
        pauseMembership: data.result.validSubs?.pauseMembershipLevel,
        email: data.result.validSubs.emailId,
        amountMonthly: data.result.validSubs.amountMonthly,
        amountYearly: data.result.validSubs.amountYearly,
        pablyMonthlyPlanId: data.result.validSubs.pablyMonthlyPlanId,
        pablyYearlyPlanId: data.result.validSubs.pablyYearlyPlanId,
        monthly_price: data.result.validSubs.amountMonthly,
        yearly_price: data.result.validSubs.amountYearly,
        successMessageSubject: data.result.validSubs.successMessageSubject,
        successMessageDescription:
          data.result.validSubs.successMessageDescription,
        renewalReminderSubject: data.result.validSubs.renewalReminderSubject,
        renewalReminderDescription:
          data.result.validSubs.renewalReminderDescription,
        idd: data.result.validSubs._id,
        membershipLevelIsFree:
          data.result.validSubs.substype?.toUpperCase() === "FREE",
        hideMembership: data?.result?.validSubs?.hideMembershipLevel ?? false,
      });
    });
    this.openModal4();
  }

  listsubscriptionType() {
    subscriptionType().then((data) => {
      if (data.status == 201) {
        this.openModal4();
      } else {
        getAllsubscription().then((resp) => {
          this.setState({ totalSubscriptions: resp?.result?.length });
          let datas = resp.result.map(function (subs) {
            return (
              <div key={subs._id} className="card mb-3 subscription-info">
                <span
                  className="position-absolute "
                  style={{
                    top: "-6px",
                    right: "-6px",
                    cursor: "pointer",
                    zIndex: "2",
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">Edit Membership Levels</Tooltip>
                    }
                  >
                  </OverlayTrigger>
                </span>
                <div className="card-body">
                  <div className="row no-gutters">
                    <div className="col-2">
                      <img
                        src={subs.thumbnailImg}
                        className="img-fluid thumbnail-img"
                        alt="book"
                      />
                    </div>
                    <div className="col-10 pl-md-4 pl-2">
                      <h5 className="heading">
                        <small style={{ color: "black" }}>Tier:</small>{" "}
                        {subs.tier}
                      </h5>
                      <h5 className="heading">
                        <small style={{ color: "black" }}>
                          Membership Unique code:{" "}
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip-top">
                                This Unique Membership Code is used when
                                uploading your members to your platform. To
                                ensure your members are applied to the correct
                                membership access level, be sure to use this
                                unique code to they access the correct
                                membership level.
                              </Tooltip>
                            }
                          >
                            <img
                              src={InfoIcon}
                              className="info-img ml-2"
                              width="16px"
                            />
                          </OverlayTrigger>{" "}
                        </small>{" "}
                        {subs._id}
                      </h5>
                      <h5 className="heading">
                        <small style={{ color: "black" }}>
                          Notified Email:{" "}
                        </small>{" "}
                        {subs.emailId}
                      </h5>
                      <p className="heading">
                        <small style={{ color: "black" }}>
                          Membership Name:
                        </small>{" "}
                        {subs.description}
                      </p>
                      {subs.substype == "Paid" ? (
                        <>
                          {/* <p className="card-text">One Time - {subs.amountOneTime}</p> */}
                          {/* <p className="card-text">Monthly  - {subs.amountMonthly}</p>  
                    <p className="card-text">Yearly  - {subs.amountYearly}</p>   */}
                          <p className="heading">
                            <small style={{ color: "black" }}>
                              Montly Charge:{" "}
                            </small>{" "}
                            {subs.amountMonthly !== 0
                              ? "$" + subs.amountMonthly
                              : "Free"}
                          </p>
                          <p className="heading">
                            <small style={{ color: "black" }}>
                              Yearly Charge:{" "}
                            </small>{" "}
                            {subs.amountYearly !== 0
                              ? "$" + subs.amountYearly
                              : "Free"}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }, this);
          this.setState({
            subsData: datas,
          });
          this.openModal7();
        });
      }
    });
  }

  tabOpen() {
    this.setState({ selectedTab: "last" });
  }
  showMemberShipPageData() {
    this.setState({ showMemberShipPage: true, showTransactionPage: false });
  }
  showTransactionPageData() {
    this.setState({ showMemberShipPage: false, showTransactionPage: true });
  }

  reset() {
    this.setState({ selectedTab: "first" });
  }
  pauseMembershipLevel(e) {
    this.setState({ pauseMembership: !this.state.pauseMembership });
  }
  hideMembershipLevel() {
    this.setState({ hideMembership: !this.state.hideMembership });
  }
  render() {
    const { isLoader, show, isOpen7 } = this.state;
    $(document).ready(function () {
      $(".createMembershipLevelModal").attr("id", "createMembershipLevelModal");
      $(".membershipBillingOptionsModal").attr(
        "id",
        "membershipBillingOptionsModal"
      );
      $(".renewalAndSuceessModal").attr("id", "renewalAndSuceessModal");
      $(".createMembershipLevelModal").attr("id", "createMembershipLevelModal");
    });
    return (
      <>
        <Header />
        <NewSidebar />

        {this.state.isSuccess ? (
          <SuccessPopup message={this.state.actionResultMessage}
            closeSuccessModel={this.closeSuccessModel} />
        ) : (
          <></>
        )}
        {this.state.showWarningModal && (
          <ConfirmationModal
            type={this.state.modalType}
            title={this.state.warningModalTitle}
            message={this.state.message}
            onConfirm={() => {
              this.setState({
                showWarningModal: false,
                message: '',
                warningModalTitle: '',
                modalType: '',
              })
              if (this.state.deleteMembershipLevelId) {
                this.deleteMemberShipLevelSubscription(this.state.deleteMembershipLevelId);
              }
            }}
            onCancel={() => this.setState({ showWarningModal: false, message: '', warningModalTitle: '', modalType: '' })}
          />
        )}
        <div className="content-wrapper admin-social-wall">
          {isLoader && <Loader />}

          <section>
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-8 col-lg-6 col-md-7">
                    <h2 className="title">Memberships</h2>
                  </div>
                  {this.state.userModuleUsedLength?.memberALLength > this.state.partnerSubsData?.membershipLevelAllowed &&
                    <OverLimitNotice message={`You have Total membership levels '${this.state.userModuleUsedLength?.memberALLength}' but Allowed membership levels in your subscription are '${this.state.partnerSubsData?.membershipLevelAllowed}'. Members will not be able to continue unless you delete some membership levels or upgrade your subscription. To delete a membership, please shift existing members to another access level.`} />
                  }
                </div>
              </div>
            </div>
          </section>

          <div className="row">
            <div className="col-md-12 mr-3 ml-3">
              <ul className="nav nav-tabs" id="coursesTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <span
                    className="nav-link active"
                    id="recentlyAdded-tab"
                    data-toggle="tab"
                    onClick={this.showMemberShipPageData.bind(this)}
                    role="tab"
                    aria-controls="recentlyAdded"
                    aria-selected="true"
                  >
                    Membership plan
                  </span>
                </li>
                <li className="nav-item" role="presentation">
                  <span
                    className="nav-link"
                    id="scheduled-tab"
                    data-toggle="tab"
                    onClick={this.showTransactionPageData.bind(this)}
                    role="tab"
                    aria-controls="scheduled"
                    aria-selected="false"
                  >
                    Member Transactions
                  </span>
                </li>
              </ul>
            </div>
          </div>

          {this.state.showMemberShipPage && (
            <div>
              <div className="row flex-wrap mt-3 w-100">
                <div className="col-xl-8 d-flex align-items-center gap-3">
                  <p
                    className="ml-4"
                    style={{ fontSize: "bold" }}
                  >
                    Sort by :
                  </p>
                  <select
                    style={{
                      color: "#888",
                      textAlign: "center",
                      background: "#EDF6F5",
                      width: "210px",
                      height: "30px",
                      border: "none",
                    }}
                    onChange={this.reverseCheckData}
                  >
                    <option value="neutral">Select Order</option>
                    <option value="high-price">High Price</option>
                    <option value="low-price">Low Price</option>
                  </select>
                </div>

                <div className="col-xl-4">
                  <div className="d-flex justify-content-end gap-3">
                    <Link
                      to="/members"
                      className="custom-button-design  mb-2 d-flex justify-content-center align-items-center"
                    >
                      List Of Members
                    </Link>
                    <button
                      onClick={this.openModal4}
                      className="custom-button-design mb-2"
                      data-toggle="modal"
                      data-target="#wantToCreateModal"
                    >
                      Add New Plan
                    </button>
                  </div>
                </div>
              </div>

              <div className="memberShip-list mr-3 ml-3">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Unique Code</th>
                        <th>Plan Name</th>
                        <th>Price</th>
                        <th>Type </th>
                        <th>No. of Members</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.subscriptionTypeData.map((item, i) => (
                        <tr key={i}>
                          <td>{item._id}</td>
                          <td>{item.tier}</td>
                          {(item.amountMonthly == null ||
                            item.amountMonthly == 0) &&
                            (item.amountYearly == null ||
                              item.amountYearly == 0) ? (
                            <td>Free</td>
                          ) : (
                            <td>
                              {"$" +
                                " " +
                                item.amountMonthly +
                                " monthly " +
                                "/" +
                                item.amountYearly +
                                " yearly "}
                            </td>
                          )}
                          <td>{item.subType}</td>
                          <td>{item.memberCount}</td>
                          <td>{item.status.split("T")[0]}</td>
                          <td>
                            <div className="dropdown dropleft">
                              <span
                                id="member-option"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </span>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="member-option"
                                style={{ zIndex: 5, right: 0 }}
                              >
                                <button
                                  type="button"
                                  className="close ml-0 pl-0 member-action-close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">
                                    <img src={close} alt="close" />
                                  </span>
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={this.editMembership.bind(
                                    this,
                                    item._id
                                  )}
                                >
                                  Edit
                                </button>
                                <span
                                  className=" mt-1"
                                  onClick={() => {
                                    if (item.memberCount > 0) {
                                      this.setState({
                                        message: "You cannot delete Plan as subscribers exist there.",
                                        modalType: "warning",
                                        warningModalTitle: "Oops!",
                                        showWarningModal: true,
                                      })

                                    } else {
                                      this.setState({
                                        message: "Do you want to delete the Membership Level Subscription?",
                                        modalType: "delete",
                                        warningModalTitle: "Delete Confirmation",
                                        showWarningModal: true,
                                        deleteMembershipLevelId: item._id,
                                      })

                                    }
                                  }}
                                >
                                  <button className="dropdown-item">
                                    Delete
                                  </button>
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {this.state.showTransactionPage && (
            <div>
              <InvoicesComponent />
            </div>
          )}
        </div>

        <Modal
          show={this.state.isOpen4}
          onHide={this.closeModal4}
          className="createMembershipLevelModal modal-wide"
          backdrop="static"
        >
          <div className="modal-header border-0 align-items-center pb-0">
            {/* <div className="go-back" onClick={this.closeModal4}>
              <img src={goback} className="img-fluid mr-3" /> GO BACK
            </div> */}
            <div className="hover-info d-flex align-items-center">
              {this.state.is_edit ? (
                <h5 className="title mb-0">Update Membership Level </h5>
              ) : (
                <h5 className="title mb-0">Create Membership Level </h5>
              )}

              <div className="hover-info ml-2 mt-1">
                <img
                  src={InfoIcon}
                  width={15}
                  height={15}
                  className="align-top info-icon"
                  alt="more"
                />
                <span className="info-hover">
                  Here you create your membership access levels. Giving it a
                  name, a short description, do you charge for it etc.
                </span>
              </div>
            </div>
            <button
              type="button"
              className="close ml-5"
              onClick={this.closeModal4}
            >
              <span aria-hidden="true">
                <img src={modalclose1} />
              </span>
            </button>
          </div>
          <Modal.Body>
            <form name="form" onSubmit={this.submitForm}>
              <div className="form-group">
                <label>
                  Name* <small>Max 50 Characters</small>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  className="form-control"
                  placeholder="GOLD"
                  onChange={this.onChange}
                  name="tier"
                  value={this.state.tier}
                />
                <p className="errorMessage">{this.state.tier_error}</p>
              </div>
              <div className="form-group">
                <label>
                  Short Description* <small>Max 150 Characters</small>
                </label>
                <input
                  type="text"
                  maxLength={150}
                  className="form-control"
                  placeholder="Enter Description"
                  onChange={this.onChange}
                  name="description"
                  value={this.state.description}
                />
                <p className="errorMessage">{this.state.description_error}</p>
              </div>
              <div className="form-group">
                <label>
                  Thumbnail Image (optional) <small>1000 x 1000px</small>
                </label>
                <label
                  className="upload-file cursor-pointer"
                  htmlFor="choose-file"
                >
                  <img src={edit} alt="img" />
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="choose-file"
                  onChange={this.onImageChange}
                />
                <div id="preview_img" className="d-flex align-items-center">
                  <img
                    height="150"
                    width="150"
                    src={this.state.gallery}
                    className="img-fluid mx-auto d-block"
                    id="uploaded-image"
                    alt="uploaded"
                  />
                </div>
                <p className="errorMessage">
                  {this.state.thumbnail_image_error}
                </p>
              </div>
              {/* <div className="form-group">
                <label>SignUp Button Text*</label>
                <input
                  type="text"
                  maxLength={20}
                  className="date form-control"
                  placeholder="Subscribe now"
                  onChange={this.onChange}
                  name="buttontext"
                  value={this.state.buttontext}
                />
                <p className="errorMessage">{this.state.buttontext_error}</p>
              </div> */}
              <div className="form-group">
                <label>Type</label>
                <select
                  className="form-control"
                  name="subType"
                  value={this.state.subType}
                  onChange={(e) => {
                    console.log("Selected Value:", e.target.value);
                    this.setState({ subType: e.target.value });
                  }}
                  style={{ width: '30%' }} 
                >
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                </select>

              </div>
              {this.state.is_edit ? (
                <div className="form-group">
                  <div className="d-flex">
                    <label>Pause Membership Level</label>
                    <div className="custom-control custom-switch ml-3">
                      <input
                        type="checkbox"
                        checked={this.state.pauseMembership}
                        className="custom-control-input"
                        name="pauseMembership"
                        id="pauseMembershipForMember"
                        onChange={this.pauseMembershipLevel.bind(this)}
                      />
                      <label
                        className="custom-control-label cursor-pointer"
                        htmlFor="pauseMembershipForMember"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="form-group">
                <div className="d-flex">
                  <label>Hide Membership Level</label>
                  <div className="custom-control custom-switch ml-3">
                    <input
                      type="checkbox"
                      checked={this.state.hideMembership}
                      className="custom-control-input"
                      name="hideMembership"
                      id="hideMembershipForMember"
                      onChange={this.hideMembershipLevel.bind(this)}
                    />
                    <label
                      className="custom-control-label cursor-pointer"
                      htmlFor="hideMembershipForMember"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>{this.state.hasFreeSubscription ? "Subscription Level" : "Is this level paid or free ?"}</label>
                <div className="access d-flex flex-column">
                  <div className="access-level">
                    <span className="float-left">Free</span>
                    <div className="custom-control custom-switch float-right">
                      <input
                        type="radio"
                        name="membesship_type"
                        className="custom-control-input"
                        id="free"
                        defaultChecked={this.state.membershipLevelIsFree}
                        onChange={this.onChange}
                        value="Free"
                      />
                      <label className="custom-control-label" htmlFor="free" />
                    </div>
                  </div>
                  {this.state.hasFreeSubscription ? <></>
                    : (
                      <div className="access-level">
                        <span className="float-left">Paid</span>
                        <div className="custom-control custom-switch float-right">
                          <input
                            type="radio"
                            name="membesship_type"
                            className="custom-control-input"
                            id="paid"
                            defaultChecked={!this.state.membershipLevelIsFree}
                            onChange={this.onChange}
                            value="Paid"
                          />
                          <label className="custom-control-label" htmlFor="paid" />
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-center">
                  <button type="submit" className="btn btn-custom">
                    Save &amp; Next
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {/* Billing Options */}
        <Modal
          show={this.state.isOpen5}
          onHide={this.closeModal5}
          className="membershipBillingOptionsModal modal-wide"
          backdrop="static"
        >
          <Modal.Body>
            <div className="go-back" onClick={this.openModal4}>
              <img src={goback} className="img-fluid mr-3" /> GO BACK
            </div>
            <h5 className="title">Billing Optionsx</h5>
            <h5 className="subtitle">
              Tier : {this.state.tier} | {this.state.membesship_type}
            </h5>
            <form name="form" onSubmit={this.submitForm1}>
              {this.state.membesship_type == "Paid" && (
                <div className="form-group">
                  <div className="price-info">
                    <label>
                      $ Price
                      <img
                        src={infoicon}
                        width={15}
                        height={15}
                        className="align-right info-icon"
                      />
                    </label>
                    <span className="info-hover">
                      Price you mention here will be displayed in the currency
                      of users country. You can enter the price in Australian
                      Dollars. Currency conversion and related fee will be done
                      on the users credit card by their bank. All prices include
                      GST.
                    </span>
                  </div>
                  <div className="row">
                    {/* <div className="col-4">                        
                     <div className="d-flex align-items-center flex-wrap">
                       <label className="price-title">ONE TIME</label>
                       <input type="number" name className="price-input form-control" placeholder="120" onChange={this.onChange} name="onetime_price" value={this.state.onetime_price}/></div>
                   </div> */}
                    <div className="col-4">
                      <div className="d-flex align-items-center flex-wrap">
                        <label className="price-title">MONTHLY</label>
                        <input
                          type="number"
                          min={0}
                          className="price-input form-control"
                          placeholder="120"
                          onChange={this.onChange}
                          name="amountMonthly"
                          value={this.state.amountMonthly}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="d-flex align-items-center flex-wrap">
                        <label className="price-title">ANNUAL</label>
                        <input
                          type="number"
                          min={0}
                          className="price-input form-control"
                          placeholder="120"
                          onChange={this.onChange}
                          name="amountYearly"
                          value={this.state.amountYearly}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="form-group">
                <label>Send Notifications to*</label>
                <input
                  required
                  type="email"
                  className="date form-control"
                  placeholder="yourname@company.com"
                  onChange={this.onChange}
                  name="email"
                  value={this.state.email}
                />
              </div>
              <div className="form-group">
                <label>Content for Email Notification*</label>
                <textarea
                  required
                  className="form-control"
                  placeholder="Type here …"
                  rows={4}
                  defaultValue={""}
                  onChange={this.onChange}
                  name="content"
                  value={this.state.content}
                />
              </div> */}
              <div className="form-group">
                <div className="d-flex justify-content-center">
                  <button type="submit" className="btn btn-custom">
                    Save &amp; Next
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {/* Renewal &amp; Success Messages */}
        <Modal
          show={this.state.isOpen6}
          onHide={this.closeModal6}
          className="renewalAndSuceessModal modal-wide"
          backdrop="static"
        >
          <div className="modal-header border-0 align-items-center">
            <div className="go-back" onClick={() => {
              this.state.substype.toUpperCase() === "FREE" ? this.openModal4() : this.openModal5()
            }}>
              <img src={goback} className="img-fluid mr-3" /> GO BACK
            </div>
            <button
              type="button"
              className="close ml-5"
              onClick={this.closeModal6}
            >
              <span aria-hidden="true">
                <img src={modalclose1} />
              </span>
            </button>
          </div>
          <Modal.Body>
            <h5 className="title">Success Messages</h5>
            {/* <h5 className="subtitle">Tier : Gold | Paid</h5> */}
            <h5 className="subtitle">
              Tier : {this.state.tier} | {this.state.membesship_type}
            </h5>
            <form name="form" onSubmit={this.submitForm2}>
              <div id="tabs">
                <ul
                  className="nav nav-pills nav-justified mb-3"
                  id="renewal-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      onClick={this.reset.bind(this)}
                      className={
                        this.state.selectedTab == "first"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="success-messages-tab"
                      data-toggle="pill"
                      href="#success-messages"
                      role="tab"
                      aria-controls="success-messages"
                      aria-selected="true"
                    >
                      Success Message
                    </a>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    <a
                      className={this.state.selectedTab == "last" ? "nav-link active" : "nav-link"}
                      id="renewal-reminder-tab"
                      data-toggle="pill"
                      href="#renewal-reminder"
                      role="tab"
                      aria-controls="renewal-reminder"
                      aria-selected="false"
                    >
                      Renewal reminder email
                    </a>
                  </li> */}
                </ul>
                <div className="tab-content" id="renewal-tabContent">
                  <div
                    className={
                      this.state.selectedTab == "first"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="success-messages"
                    role="tabpanel"
                    aria-labelledby="success-messages-tab"
                  >
                    {/* <div className="form-group">
                      <label>
                        Subject <small>Max 150 Characters</small>
                      </label>
                      <input
                        maxLength={150}
                        type="text"
                        className="form-control"
                        placeholder="Thanks for subscribing to Gold Plan"
                        onChange={this.onChange}
                        name="successMessageSubject"
                        value={this.state.successMessageSubject}
                      />
                    </div> */}
                    <div className="form-group">
                      <label>
                        Note <small>Max 1000 Characters</small>
                      </label>
                      <textarea
                        maxLength={1000}
                        className="form-control"
                        placeholder="Hi,
Thanks for subscribing to our gold plan.
We have exciting content lined up for you, for whole of this month and Will notify when ever new content is available."
                        rows={6}
                        defaultValue={""}
                        onChange={this.onChange}
                        name="successMessageDescription"
                        value={this.state.successMessageDescription}
                      />
                      {/* <p>
                        Member Name, Member Email, Member Phone number, Member Membership Level,
                        Membership Charge
                      </p> */}
                    </div>
                    <div className="form-group">
                      <div className="d-flex justify-content-center">
                        {/* <button
                          onClick={this.tabOpen.bind(this)}
                          type="button"
                          className="btn btn-custom"
                        >
                          Save &amp; Next
                        </button> */}
                        <button type="submit" className="btn btn-custom">
                          {this.state.is_edit ? "Update" : "Save"}
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* <div
                    className={
                      this.state.selectedTab == "last"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="renewal-reminder"
                    role="tabpanel"
                    aria-labelledby="renewal-reminder-tab"
                  >
                    <div className="attach-info float-right">
                      <img
                        src="images/dashboard/ic_error.svg"
                        width={13}
                        height={13}
                        className="align-bottom"
                      />
                      <span className="info">Message type here</span>
                    </div>
                    <div className="form-group">
                      <label>
                        Subject <small>Max 150 Characters</small>
                      </label>
                      <input
                        maxLength={150}
                        type="text"
                        className="form-control"
                        placeholder="Renewal reminder : Membership Name - GOLD PLAN"
                        onChange={this.onChange}
                        name="renewalReminderSubject"
                        value={this.state.renewalReminderSubject}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Description <small>Max 1000 Characters</small>
                      </label>
                      <textarea
                        maxLength={1000}
                        className="form-control"
                        placeholder="Hi {Member Name},
Thanks for subscribing to our gold plan.
We have exciting content lined up for you, for whole of this month and Will notify when ever new content is available."
                        rows={6}
                        defaultValue={""}
                        onChange={this.onChange}
                        name="renewalReminderDescription"
                        value={this.state.renewalReminderDescription}
                      />
                      <p>
                        Member Name, Member Email, Member Phone number, Member Membership Level,
                        Membership Charge
                      </p>
                    </div>
                    <div className="form-group">
                      <div className="d-flex justify-content-center">
                        <button type="submit" className="btn btn-custom">
                          {this.state.is_edit ? "Update" : "Save"}
                        </button>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default MemberShips;
